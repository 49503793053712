import { useEffect } from 'react';

const ONE_HOUR = 60 * 60 * 1000; // milliseconds

const useAutoClearLocalStorage = () => {
  useEffect(() => {
    const lastClear = localStorage.getItem('lastClearTime');
    const now = Date.now();

    if (!lastClear || now - parseInt(lastClear) > ONE_HOUR) {
      localStorage.removeItem('selectedTrack');
      localStorage.removeItem('selectedPackage');
      localStorage.removeItem('promotionTracks');
      localStorage.setItem('lastClearTime', now.toString());
    }

    const interval = setInterval(() => {
      localStorage.removeItem('selectedTrack');
      localStorage.removeItem('selectedPackage');
      localStorage.removeItem('promotionTracks');
      localStorage.setItem('lastClearTime', Date.now().toString());
    }, ONE_HOUR);

    return () => clearInterval(interval);
  }, []);
};

export default useAutoClearLocalStorage;
